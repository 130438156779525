import { useStorage } from "@vueuse/core";
import { defineStore } from "pinia";
import { ref } from "vue";

export const useOptionsStore = defineStore("options", () => {
  const dev = useStorage('aicope-options', {
    enableInProduction: false,
    disableSurveys: false,
    disableWaitingList: false
  })

  const showExternalLinkAlert = ref(true);

  const skipSurvey = ref(false);

  return { dev, showExternalLinkAlert, skipSurvey };
});
