import { createWebHistory, createRouter } from "vue-router";
import { RouteRecordRaw } from "vue-router";
import auth, { UserRole } from "@/services/auth";
import { useSurveyStore } from "./stores/surveys";
import log from "loglevel";
import { useOptionsStore } from "./stores/options";

declare module 'vue-router' {
  interface RouteMeta {
    headline?: string;
    description?: string;
    parentName?: string;
    transition?: string;
    inBottomNav?: boolean;
    bottomNavTitle?: string;
  }
}

export const default_route_name = "home";

const routes: Array<RouteRecordRaw> = [
  {
    path: "/",
    component: () => import("./layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: default_route_name,
        components: {
          default: () => import("./pages/HomePage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
        },
        meta: {
          headline: "Vaše zdravotní informace na jednom místě",
          inBottomNav: false,
          bottomNavTitle: "Domov",
        }
      },
      {
        path: "informace",
        children: [
          {
            path: "",
            name: "infolib",
            components: {
              default: () => import("./pages/InfolibPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            meta: {
              headline: "Zdroje informací",
              //description: "Prohlížejte důveryhodné zdroje, nebo hledejte přímo, co Vás zajímá.",
              inBottomNav: true,
              bottomNavTitle: "Zdroje informací"
            },
            children: [
              {
                path: "/vyhledat/:query",
                name: "infolib-search",
                component: () => import("./components/InfolibSearch.vue"),
                props: {
                  default: true
                }
              }
            ]
          },
          {
            path: "brozury/:fileId",
            name: "infolib-brochure-viewer",
            // bypass main component
            component: () => import("./pages/InfolibBrochureViewerPage.vue"),
            props: {
              default: true,
              LeftSidebar: false,
            },
            meta: {
              transition: "fade",
            }
          },
          {
            path: "weby",
            name: "infolib-websites",
            components: {
              default: () => import("./pages/InfolibWebsitesPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            meta: {
              headline: "Webové portály",
              transition: "fade",
              parentName: "infolib"
            }
          },
          {
            path: "weby/:domain",
            name: "infolib-website-detail",
            components: {
              default: () => import("./pages/InfolibWebsiteDetailPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            props: {
              default: true,
              LeftSidebar: false,
            },
            meta: {
              headline: "Webový portál",
              transition: "fade",
              parentName: "infolib-websites"
            }
          },
          {
            path: "brozury",
            name: "infolib-brochures",
            components: {
              default: () => import("./pages/InfolibBrochuresPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            meta: {
              headline: "Brožury",
              transition: "fade",
              parentName: "infolib"
            }
          }
        ],
      },
      {
        path: "lekarske-zpravy/:recordId(\\d+)?",
        name: "health-records",
        components: {
          default: () => import("./pages/HealthReportsPage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
        },
        meta: {
          headline: "Vaše lékařské zprávy",
          inBottomNav: true,
          bottomNavTitle: "Zprávy"
        }
      },
      {
        path: "leky",
        children: [
          {
            path: "",
            name: "drugs",
            components: {
              default: () => import("./pages/DrugsPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            meta: {
              headline: "Léky",
              inBottomNav: true,
              bottomNavTitle: "Léky"
            }
          },
          {
            path: "detail/:id",
            name: "drugs-detail",
            components: {
              default: () => import("./pages/DrugsDetailPage.vue"),
              LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
            },
            meta: {
              headline: "Informace o léku",
              parentName: "drugs"
            }
          },
          {
            path: "letak/:type/:drugId",
            name: "drugs-document-viewer",
            // bypass main component
            component: () => import("./pages/DrugsDocumentViewerPage.vue"),
            props: {
              default: true,
              LeftSidebar: false,
            },
            meta: {
              transition: "fade",
            }
          },
        ],
      },
      {
        path: "porad-mi",
        name: "qa",
        components: {
          default: () => import("./pages/QAPage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
        },
        meta: {
          headline: "Chci se zeptat dotaz",
          inBottomNav: true,
          bottomNavTitle: "Poraď"
        }
      }
    ],
  },
  {
    path: "/dokoncit-prihlaseni",
    name: "complete_login",
    component: () => import("./pages/auth/CompleteLoginPage.vue"),
  },
  {
    path: "/odhlasen",
    name: "logout-successful",
    component: () => import("./pages/auth/LogoutSuccessfulPage.vue"),
  },
  {
    path: "/studie",
    name: "survey",
    component: () => import("./pages/SurveyPage.vue"),
  },
  {
    path: "/pripravy",
    name: "waiting-list",
    component: () => import("./pages/WaitingListPage.vue"),
  },
  {
    path: "/doktor",
    component: () => import("./layouts/MainLayout.vue"),
    props: {
      disableChildComponentTransition: true,
    },
    children: [
      {
        path: "",
        name: "doctor-home",
        components: {
          default: () => import("./pages/DoctorHomePage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimaryDoctors.vue"),
        },
      },
      {
        path: "studie",
        name: "doctor-surveys",
        components: {
          default: () => import("./pages/DoctorSurveysPage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimaryDoctors.vue"),
        },
      },
      {
        path: "registrace-pacienta",
        name: "doctor-patient-registration",
        components: {
          default: () => import("./pages/DoctorRegisterPatientPage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimaryDoctors.vue"),
        },
      },
    ]
  },
  {
    path: "/dev-test",
    name: "dev-test",
    component: () => import("./pages/_TestPage.vue"),
  },
  {
    path: "/chyba",
    name: "error",
    component: () => import("./pages/ErrorPage.vue"),
    meta: {
      headline: "Nastala chyba"
    }
  },
  {
    path: "/:pathMatch(.*)*",
    name: "NotFound",
    component: () => import("./layouts/MainLayout.vue"),
    children: [
      {
        path: "",
        name: "404",
        components: {
          default: () => import("./pages/NotFoundPage.vue"),
          LeftSidebar: () => import("./components/NavLeftPrimary.vue"),
        },
      },
    ],
  },
];

let recordsSavedPosition: any = null;

const router = createRouter({
  history: createWebHistory(),
  routes,

  // https://router.vuejs.org/guide/advanced/scroll-behavior.html
  scrollBehavior(to, from, savedPosition) {
    // hash change when scrolling between records
    if (to.name == "health-records" && from.name == "health-records") {
      return savedPosition;
    } else if (from.name === "health-records") {
      recordsSavedPosition = savedPosition;
    } else if (to.name === "health-records") {
      //log.debug("scrollBehavior: returning to records with recordsSavedPosition ", recordsSavedPosition, " and savedPosition ", savedPosition)
      if (recordsSavedPosition) return recordsSavedPosition;
      else if (to.hash) {
        return {
          el: `#record-${to.hash.slice(1)}`,
          behavior: "smooth",
        };
      }
    }

    if (savedPosition) {
      return savedPosition;
    } else {
      return { top: 0, behavior: 'smooth' };
    }
  },
});

// possibly todo: save scroll state for each page
// https://medium.com/@aryan02420/vue-router-restore-scroll-position-with-router-link-and-transition-61396af48ba2
/* cannot have multiple guards router.beforeEach((to, from, next) => {
  log.debug('window.scrollY:', window.scrollY)
  from.meta?.scrollTop && (from.meta.scrollTop = window.scrollY)
  log.debug('from:\t', from.name, '\t', from.meta)
  log.debug('to:\t\t', to.name, '\t', to.meta)
  return next()
}) */

// redirect if unauthenticated
router.beforeEach(async (to, from, next) => {
  log.trace("Router: beforeEach guard from ", from.name, " to ", to.name);
  if (to.name === "logout-successful") {
    log.debug("Router: publicNoAuth flag on route, skipping auth check.")
    return next();
  }

  if (!auth.is_logged_in()) {
    log.debug("Router: initiating auth procedure (login/refresh).");
    await auth.refresh_token_or_start_login();
  } else {
    if (auth.user_profile.value?.role == UserRole.SurveyRespondent && to.name !== "survey") {
      log.debug("Router: user is survey respondent, redirecting to survey.");
      router.replace({ name: "survey" });
      return next();
    }
  }

  const options = useOptionsStore();

  // surveys
  if (!options.dev.disableSurveys && !auth.is_user_doctor() && !options.skipSurvey) {
    // stop if already redirecting to survey
    if (to.name === "survey") {
      log.debug("Router: already redirecting to survey, skip.");
      return next();
    }

    const surveys = useSurveyStore();
    if (surveys.isServiceReachable() && surveys.isServiceReady()) {
      if (await surveys.hasOpenSurvey()) {
        log.info("Router: user has open survey, redirecting to it.");
        router.replace({ name: "survey" });
        return next();
      } else if (await surveys.openForcedSurvey()) {
        log.info(
          "Router: user has no open survey, but available assignment. Forced opened and redirecting.",
        );
        router.replace({ name: "survey" });
        return next();
      }
    } else {
      log.debug("Router: surveys skipped, service was not reachable on initial load.");
    }
  } else {
    log.debug("Router: surveys bypassed.");
  }

  // redirect doctor to admin interface
  if (auth.is_user_doctor() && to.name === default_route_name) {
    log.debug("Router: redirecting to doctor interface.");
    router.replace({ name: "doctor-home" });
    return next();
  }

  // redirect patient from doctor interface
  if (!auth.is_user_doctor() && to.path.startsWith("/doktor")) {
    log.debug("Router: redirecting to patient interface.");
    router.replace({ name: "home" });
    return next();
  }
  next();
});

export default router;
