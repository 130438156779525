import { Client, cacheExchange, fetchExchange } from "@urql/vue";
import { authExchange } from "@urql/exchange-auth";
import { retryExchange } from "@urql/exchange-retry";
import { default as authService } from "./auth";
import createClient from "openapi-fetch";
import surveysApiTypes from "@/helpers/api/surveys-v1";
import infolibApiTypes from "@/helpers/api/infolib-v1";
import wikiApiTypes from "@/helpers/api/wiki-v1";
import log from 'loglevel';

//
// REST endpoints
//

// intercept 401 by wrapping fetch to refresh auth
const { fetch: originalFetch } = window;
const fetchWithAuth = async (...args: any) => {
  let [resource, config] = args;
  let response = await originalFetch(resource, config);
  if (!response.ok && response.status === 401) {
    console.log("401 intercepted. Refreshing auth.")
    try {
      await authService.refresh_token();
    } catch (e) {
      log.warn("Failed to refresh auth token. Redirecting to login.");
      authService.start_login();
    }
    return Promise.reject(response);
  }
  return response;
};

const surveys = createClient<surveysApiTypes.paths>({ baseUrl: window._env_.VITE_SURVEYS_API_URL, credentials: "include", fetch: fetchWithAuth });
const infolib = createClient<infolibApiTypes.paths>({ baseUrl: window._env_.VITE_INFOLIB_API_URL, credentials: "include", fetch: fetchWithAuth });
const wiki = createClient<wikiApiTypes.paths>({ baseUrl: window._env_.VITE_WIKI_API_URL, fetch: fetchWithAuth });

//
// GraphQL endpoint
//
if (!window._env_.VITE_GATEWAY_API_GQL_URL) {
  throw new Error("Http-common: invalid env.");
}

const gatewayGql = new Client({
  url: window._env_.VITE_GATEWAY_API_GQL_URL,
  fetchOptions: () => { return { credentials: "include", headers: { 'request-id': self.crypto.randomUUID() } } },
  exchanges: [
    //devtoolsExchange, // should be first
    cacheExchange,
    // must be placed between all synchronous (cache) and async (fetch) exchanges
    // docs https://formidable.com/open-source/urql/docs/advanced/authentication/
    // example: https://github.com/urql-graphql/urql/blob/main/examples/with-refresh-auth/src/client.js
    authExchange(async (_) => {
      return {
        // executed before every operation
        addAuthToOperation(op) {
          // used for adding auth headers
          // don't need to implement, as auth token is a cookie sent automatically
          // however, this function is required for authExchange
          return op;
        },
        // forbids to send any request to prevent data leak
        willAuthError(_operation) {
          return authService.has_login_expired();
        },
        // should check what is the error type, and if it was 401
        didAuthError(error, _operation) {
          log.trace("GraphQL endpoind: didAuthError");
          // TODO: what is the error sent by gateway API?
          // in case it doesn't return as part of gql response only http, check for 401
          return error.graphQLErrors.some(
            (e) => e.extensions?.code === "FORBIDDEN"
          );
        },
        async refreshAuth() {
          log.trace("GraphQL endpoind: forbidden returned. Refreshing auth.");
          try {
            await authService.refresh_token();
          } catch (e) {
            log.warn("Failed to refresh auth token. Redirecting to login.");
            authService.start_login();
          }
        },
      };
    }),
    retryExchange({
      initialDelayMs: 2000,
      maxDelayMs: 15000,
      randomDelay: true,
      maxNumberAttempts: 2,
      retryIf: (err: any) => err && err.networkError,
    }), // before fetch, https://formidable.com/open-source/urql/docs/advanced/retry-operations/
    fetchExchange,
  ],
});

export { surveys, infolib, wiki, gatewayGql };
