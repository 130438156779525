import { DrugEntity, DrugInteraction, FoodInteraction, GetAllPatientDrugInteractionsDocument, Interactions, GetPatientDrugsQuery } from './../gql/graphql';
import { ref } from 'vue';
import { defineStore } from "pinia";
import { useQuery } from "@urql/vue";
import { GetPatientDrugsDocument } from "@/gql/graphql";
import { infolib } from '@/services/http-common';
import * as infolib_t from "@/helpers/api/infolib-v1";
import log from 'loglevel';

export enum StoreState {
  EMPTY = "EMPTY",
  LOADING = "LOADING",
  READY = "READY",
  ERROR = "ERROR"
}

// this is a workaround, as GetPatientDrugsQuery field of drugs is not typed correctly by codegen, or didn't know how to use it:
//      GetPatientDrugsQuery['me']['userRole']['drugs']
// doesn't work
type PatientDrugsArray = NonNullable<NonNullable<GetPatientDrugsQuery['me']>['userRole']>['drugs'];
type InfolibDrugsLinkosResponse =
  infolib_t.paths["/drugs/linkos"]["post"]["responses"][200]["content"]["application/json"];
export type SingleDrugInteraction = { with: DrugEntity['id'], interactions: DrugInteraction };
export type FoodInteractionEntry = { withDrugs: DrugEntity['id'][], effects: FoodInteraction['effect'][], sources: FoodInteraction['source'][]};
export type AllPatientDrugsInteractionsMap = Record<DrugEntity['id'], SingleDrugInteraction[]>;
export type AllPatientFoodsInteractionsMap = Record<FoodInteraction['name'], FoodInteractionEntry>;

export const useDrugsStore = defineStore("drugs", () => {
  const state = ref<StoreState>(StoreState.EMPTY);
  const drugs = ref<PatientDrugsArray>([]);
  const drugsMap = ref<Record<DrugEntity['id'], DrugEntity>>({});
  const linkosGlossaryEntries = ref<InfolibDrugsLinkosResponse>({});
  const interactions = ref<Record<DrugEntity['id'], Interactions&{ atcCode: string }>>({});
  const patientDrugsInteractions = ref<AllPatientDrugsInteractionsMap>({});
  const patientFoodsInteractions = ref<AllPatientFoodsInteractionsMap>({});

  async function load(): Promise<void> {
    if (state.value === StoreState.READY || state.value === StoreState.LOADING) {
      return Promise.resolve();
    }

    state.value = StoreState.LOADING;
    log.info("Store/drugs: load proceeding.");
    try {
      queryAll();
    } catch (e) {
      log.error("Store/drugs: load failed. ", e);
      state.value = StoreState.ERROR;
      return Promise.reject(e);
    }
  }

  const queryAll = () => {
    useQuery({
      query: GetPatientDrugsDocument, variables: { limit: 1000000 }
    }).then(async (result) => {
      if (result.data.value && !result.error.value) {
        //log.trace("Store/drugs: loaded. Data: ", result.data);
        drugs.value = result.data.value.me.userRole?.drugs ?? [];
        drugsMap.value = {};
        result.data.value.me.userRole?.drugs.forEach(item => {
          log.trace("Fetched drug: ", item);
          // TODO!!!!
          //@ts-ignore
          drugsMap.value[item.drug.id] = item.drug;
        });
        //log.trace(result.data.value.me.userRole?.drugs);
        state.value = StoreState.READY;

        // Load linkos's glossary entries for all reported drugs
        const atcCodes = [];
        for (const drug of drugs.value) {
          const atcCode = drug.drug?.atcCode;
          if (atcCode) atcCodes.push(atcCode);
        }
        await getLinkosInfo(atcCodes);
        //log.trace("Store/drugs: linkos entries loaded: ", linkosEntries);
      } else {
        //log.trace("Store/drugs: failed to load.");
        state.value = StoreState.ERROR;
      }
    });

    useQuery({
      query: GetAllPatientDrugInteractionsDocument,
      variables: {
        limit: 1000000,
      }
    }).then(async (result) => {
      log.trace("Store/drugs: loaded interactions. Data: ", result.data);
      if (!result.data.value) {
        log.error("Store/drugs: failed to load interactions.");
        return;
      }

      const respDrugArr = result.data.value.me.userRole?.drugs ?? [];
      for (const o of respDrugArr) {
        const newInter = o.drug?.interactions as Interactions;
        newInter.drugInteractions = newInter.drugInteractions.filter(interaction => 
          interaction.effect !== 'snížení metabolismu' && interaction.effect !== 'zvýšení metabolismu'
        );
        interactions.value[o.drug?.id] = { ...newInter, atcCode: o.drug?.atcCode };
        //log.info("Store/drugs: loaded interactions for drug ", o.drug?.id, ": ", { ...o.drug.interactions as Interactions, atcCode: o.drug?.atcCode })

        // save food interactions
        const foodNames : string[] = [];
        for (const foodI of newInter.foodInteractions) {
          log.trace("Food:", foodI.name)
          if (foodI.name.toLowerCase() == 'Zyflamend') continue;
          if (foodI.name.toLowerCase() == 'sophora flavescens') foodI.name = 'jerlín žlutavý (sophora flavescens)';
          // fix duplicates from API
          if (foodNames.includes(foodI.name.toLowerCase())) continue;
          else foodNames.push(foodI.name.toLowerCase());

          if (patientFoodsInteractions.value[foodI.name] === undefined) { //o.drug?.id
            patientFoodsInteractions.value[foodI.name] = { withDrugs: [], effects: [], sources: [] };
          }
          const entry = patientFoodsInteractions.value[foodI.name];
          if (entry) {
            if (!entry.withDrugs.includes(o.drug?.id)) entry.withDrugs.push(o.drug?.id);
            const ef = preprocessFoodEffectName(foodI.effect);
            if (!entry.effects.includes(ef) && ef.length) entry.effects.push(ef);
            if (!entry.sources.includes(foodI.source)) entry.sources.push(foodI.source);
          }
        }
      }

      /* for (const [key, val] of Object.entries(interactions.value)) {
        //console.log("Checking interactions for ", key, val)
        //patientDrugsInteractions.value[key] = [];
        let targetAtc = val.atcCode;
        for (const patientDrug of drugs.value) {
          if (!patientDrug.drug?.atcCode || patientDrug.drug?.id === key) {
            continue;
          }
          //console.log("   -> ", targetAtc," with ", patientDrug.drug?.atcCode, " ", patientDrug.drug?.id, val.drugInteractions);
          const foundInteraction = val.drugInteractions.find((interaction => interaction.atcCode === patientDrug.drug?.atcCode));
          if (foundInteraction) {
            console.log("Store/drugs: found interaction between ", targetAtc, " and ", patientDrug.drug?.atcCode);
            if (foundInteraction.effect === 'snížení metabolismu' || foundInteraction.effect === 'zvýšení metabolismu') {
              continue;
            }
            if (patientDrugsInteractions.value[key] === undefined) {
              patientDrugsInteractions.value[key] = [];
            }
            patientDrugsInteractions.value[key]?.push({ with: patientDrug.drug?.id ?? "", interactions: foundInteraction });
          }
        }
      } */
    });
  }

  async function getLinkosInfo(atcCodes: string[]): Promise<InfolibDrugsLinkosResponse> {
    //console.log("Store/drugs: getLinkosInfo proceeding with atc codes: ", atcCodes)
    // look in cache first for single entries
    if (atcCodes.length === 1 && atcCodes[0]) {
      const atcCode = atcCodes[0];
      const entry = linkosGlossaryEntries.value[atcCode];
      if (entry) {
        const resp: InfolibDrugsLinkosResponse = {};
        resp[atcCode] = entry;
        //console.log("Store/drugs: getLinkosInfo used cache for ", atcCode)
        return Promise.resolve(resp);
      }
    }

    // cache miss, or multiple entries are used to preload data
    try {
      const { data, error } = await infolib.POST(
        '/drugs/linkos',
        { body: { atcCodes } }
      );
      //console.log("Store/drugs: getLinkosInfo attempted to retrieve online info for ", atcCodes)
      if (data && !error) {
        if (Object.keys(data).length === 0) {
          return Promise.reject("No data found for given atc codes.");
        }
        for (const [key, val] of Object.entries(data)) {
          val.explanation = val.explanation.replace(/Informace o léku na informačním portálu[\s\S]*/, '');
          val.explanation = val.explanation.replace(/Registrované indikace[\s\S]*?(?=Způsob užívání léku)/, '');
        }
        linkosGlossaryEntries.value = Object.assign({}, linkosGlossaryEntries.value, data);
        //console.log("Store/drugs: getLinkosInfo loaded. Fetched: ", data);
        //console.log("Store/drugs: getLinkosInfo loaded. Stored as: ", linkosGlossaryEntries.value);
        return Promise.resolve(data);
      }
    } catch (e) {
      log.error(e);
      return Promise.reject(e);
    }
    return Promise.reject("Failed to fetch linkos drug data from infolib.");
  }

  return { drugs, drugsMap, load, state, getLinkosInfo, interactions, patientDrugsInteractions, patientFoodsInteractions };
});

export function preprocessFoodEffectName(effect: string): string {
  switch (effect) {
    case 'zvýšení účinnosti':
      return 'neužívejte (zvyšuje účinek léku)';
    case 'snížení účinnosti':
      return 'neužívejte (snižuje účinek léku)';
    case 'snížení nežádoucích účinků':
      return 'doporučuje se (snižuje nežádoucí účinky léku)';
    case 'interaguje s':
      return 'komplikované (konzultujte)';
  }
  return effect;
}