import { defineStore } from "pinia";
import { ref } from "vue";
import log from "loglevel";
import * as api from "@/services/http-common";
import * as survey_t from "@/helpers/api/surveys-v1";
import until from "./helpers/until";

export enum StoreState {
  EMPTY = "EMPTY",
  LOADING = "LOADING",
  READY = "READY",
  UNREACHABLE = "UNREACHABLE",
}

type SurveyResponseGetCurrent =
  survey_t.paths["/user/assignments/current"]["get"]["responses"][200]["content"]["application/json"];
type SurveyResponseGetAssignments =
  survey_t.paths["/user/assignments"]["get"]["responses"][200]["content"]["application/json"];

export const useSurveyStore = defineStore("survey", () => {
  const state = ref<StoreState>(StoreState.EMPTY);
  const current_survey = ref<SurveyResponseGetCurrent | null>(null);
  const current_survey_title = ref<string>("Průzkum");
  const assignments = ref<SurveyResponseGetAssignments | null>(null);
  const forceFullscreen = ref(false);

  async function load(): Promise<void> {
    if (state.value === StoreState.READY) {
      return Promise.resolve();
    }
    if (state.value === StoreState.LOADING) {
      await until((_: any) => state.value == StoreState.READY);
      return Promise.resolve();
    }

    log.debug("Surveys: load proceeding.");
    await reloadSurveys().then(
      () => {
        log.info("Surveys: loaded.");
      },
      () => {
        log.info("Surveys: couldn't load.");
      }
    );
  }

  function isServiceReachable(): boolean {
    return state.value !== StoreState.UNREACHABLE;
  }

  function isServiceReady(): boolean {
    return state.value === StoreState.READY;
  }

  /**
   * Fetches current survey assignment and all available assignments
   */
  async function reloadSurveys(): Promise<void> {
    try {
      state.value = StoreState.LOADING;
      await Promise.all([fetchCurrentSurvey(), fetchAssignments()]).then(() => {
        state.value = StoreState.READY;
      });
      return;
    } catch (e: any) {
      state.value = StoreState.UNREACHABLE;
      return Promise.reject(
        new Error("Surveys: error loading (is service reachable?):"+e?.message)
      );
    }
  }

  async function fetchCurrentSurvey(): Promise<void> {
    const { data, error, response } = await api.surveys.GET(
      "/user/assignments/current",
      {}
    );

    if ((response as any)?.status == 204) {
      log.info("Surveys: No current survey assignment.");
      current_survey.value = null;
      return Promise.resolve();
    }

    if (error) {
      log.error(
        "Surveys: Cannot fetch current survey: ",
        error,
        ". Is surveyAPI up and is user authenticated? Ignoring survey module."
      );
      current_survey.value = null;
    } else {
      log.info("Surveys: current assignment loaded: ", data);
      current_survey.value = data;
    }
  }

  async function fetchAssignments(): Promise<void> {
    try {
      const { data } = await api.surveys.GET(
        "/user/assignments",
        {}
      );
      if (data && data?.length === 0) {
        assignments.value = null;
        return Promise.resolve();
      } else {
        log.info("Surveys: assignments available: ", data);
        assignments.value = data ? data : null;
      }
    } catch (e: any) {
      log.error(
        "Surveys: Cannot fetch survey assignments:",
        e?.message,
        ". Is survey API up and is user authenticated?"
      );
    }
  }

  /**
   * @returns true if the user is responding a survey, false otherwise
   */
  async function hasOpenSurvey(): Promise<boolean> {
    if (state.value === StoreState.UNREACHABLE) return false;
    await until(
      (_: any) => state && state?.value && state.value === StoreState.READY
    );

    return !!current_survey.value;
  }

  /**
   * Opens survey with forceOpen flag if any
   * @returns true if survey was opened, false if no forcable survey was found
   */
  async function openForcedSurvey(): Promise<boolean> {
    if (state.value === StoreState.UNREACHABLE) return false;
    await until((_: any) => state.value === StoreState.READY);

    // there is no current survey, but there are assignments available
    if (current_survey.value || !assignments.value) return false;
    const openableAssignments = assignments.value?.filter(
      (a) => a.shouldForceOpen
    );
    if (!openableAssignments.length) return false;
    const openableAssignment = openableAssignments[0];
    if (!openableAssignment) return false;

    const { data, error } = await api.surveys.POST(
      `/user/assignments/open/{assignmentId}`,
      { params: { path: { assignmentId: openableAssignment.id } } }
    );
    if (error) {
      log.error("Surveys: Cannot open survey:", error);
      return false;
    }

    current_survey.value = data;
    log.info("Opened forced survey: ", current_survey.value);
    return true;
  }

  async function updateSubmission(
    submissionData: { data: any, currentPage: number, percentCompleted: number },
    isCompleted: boolean = false
  ): Promise<boolean> {
    if (!current_survey.value)
      throw new Error(
        "Surveys: No current survey open but attempted to send submission."
      );

    console.log("store/updateSubmission payload:", submissionData, isCompleted);
    current_survey.value.submission.percentCompleted = submissionData?.percentCompleted;

    try {
      await api.surveys.PUT(
        "/user/submission/{submissionId}",
        {
          params: { path: { submissionId: current_survey.value.submission.id } },
          body: {
            data: submissionData?.data, //current_survey.value.submission.data,
            isCompleted,
            percentCompleted: submissionData?.percentCompleted,
            // @ts-ignore
            currentPage: submissionData?.currentPage,
          },
        }
      );
    } catch (e) {
      log.error("Surveys: Cannot update submission:", e);
      return false;
    }

    // reload store
    if (isCompleted) {
      await reloadSurveys();
      log.info("Surveys: survey finished, successfully submitted and closed.");
    } else {
      log.info("Surveys: submission updated but not closed.");
    }
    return true;
  }

  return {
    load,
    current_survey,
    hasOpenSurvey,
    openForcedSurvey,
    current_survey_title,
    updateSubmission,
    isServiceReachable,
    isServiceReady,
    forceFullscreen
  };
});
