<template>
  <!-- <n-theme-editor> -->
  <n-config-provider :theme-overrides="themeOverrides">
    <n-global-style />
    <n-loading-bar-provider :loading-bar-style="{ colorLoading: '#58B9B0FF' }">
      <n-dialog-provider>
        <n-message-provider>
          <Suspense><router-view /></Suspense>
        </n-message-provider>
      </n-dialog-provider>
    </n-loading-bar-provider>
  </n-config-provider>
  <!-- </n-theme-editor> -->
</template>

<script setup lang="ts">
//import { darkTheme } from 'naive-ui'
import { NThemeEditor, NConfigProvider } from "naive-ui";
import * as themeOverrides from "./assets/naive-ui-theme-overrides.json";
import { useSurveyStore } from "./stores/surveys";
import auth, { UserRole } from "@/services/auth";
import log from "loglevel";
import router from "./router";
import { useBreakpoints, breakpointsTailwind } from "@vueuse/core";
import { onMounted, provide, ref } from "vue";
import { useOptionsStore } from "./stores/options";
import * as Sentry from "@sentry/vue"
import { UAParser } from 'ua-parser-js';
import { useReportsStore } from "./stores/reports";
import { useDrugsStore } from "./stores/drugs";

const mobile = ref(useBreakpoints(breakpointsTailwind).smallerOrEqual("lg"));
provide("mobile", mobile);
const optionsStore = useOptionsStore();
const devEnabled = ref<boolean>(window?._env_?.VITE_ENV === 'development' || optionsStore.dev.enableInProduction);
provide("devEnabled", devEnabled);

if (!auth.is_user_doctor()) {
  const reportsStore = useReportsStore();
  const drugsStore = useDrugsStore();
  reportsStore.load();
  drugsStore.load();
}

// log device info
const deviceInfo: any = new UAParser().getResult();
const resolutions = {
  "screen": window.screen ? window.screen.width + 'x' + window.screen.height : null,
  "screenSpaceAvailable": window.screen ? window.screen.availWidth + 'x' + window.screen.availHeight : null,
  "browserWindow": window.innerWidth + 'x' + window.innerHeight,
  "devicePixelRatio": window.devicePixelRatio,
};
deviceInfo['resolutions'] = resolutions;
log.info(deviceInfo);

// setup sentry details
onMounted(() => {
  if (auth.is_logged_in()) {
    console.log(`Logged in, will set Sentry user from ${JSON.stringify(auth.user_profile.value)}`);
    if (auth.user_profile.value) {
      console.log(`Setting userId for Sentry: ${auth.user_profile.value.id}`);
      Sentry.setUser({ id: auth.user_profile.value.id as string });
    } else {
      log.error("Cannot set userId for Sentry: user profile still not loaded.");
    }
  }
  if (devEnabled.value) {
    Sentry.setUser({ id: 'dev' });
  }
});

try {
  log.info(`User role: ${auth.user_profile.value?.role}`);

  if (auth.user_profile.value?.role == UserRole.SurveyRespondent) {
    log.debug("Router: user is survey respondent, redirecting to survey.");
    router.replace({ name: "survey" });
  }

  if (auth.user_profile.value?.role == UserRole.Doctor) {
    log.debug("Router: user is doctor, redirect to doctor's interface.");
    router.replace({ name: "doctor-home" });
  }

  const surveyStore = useSurveyStore();
  if (!auth.is_user_doctor()) {
    surveyStore.load().then(async () => {
      if (await surveyStore.hasOpenSurvey()) {
        log.info("App: open survey, redirecting to survey page.");
        router.replace({ name: "survey" });
      }
      if (await surveyStore.openForcedSurvey()) {
        log.info(
          "Router: user has no open survey, but available assignment. Forced opened and redirecting.",
        );
        router.replace({ name: "survey" });
      }
    })
  } else {
    if (router.currentRoute.value.name === 'waiting-list') {
      router.push({ name: "doctor" });
    }
  }
} catch (e) {
  log.error(e);
}
</script>