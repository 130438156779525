import { createApp } from "vue";
import "./index.css";
import App from "./App.vue";
import { createPinia } from "pinia";
import router from "./router";
import { plugin as FormkitPlugin, defaultConfig as FormkitDefaultConfig } from '@formkit/vue'
import { autoAnimatePlugin } from "@formkit/auto-animate/vue";
import "@formkit/themes/genesis";
import { cs } from '@formkit/i18n'
import log from "loglevel";
import * as Sentry from "@sentry/vue";
import urql from "@urql/vue";
import { gatewayGql } from './services/http-common'
import auth from './services/auth'

// environment variables are stored in "/public/env.js"
// however, they cannot be loaded later than this file
// maybe one day deployment gods will think of my sanity
// for now, we have to do this
window._env_.VITE_ENV = "production";
auth.init();

// Ensure naive-ui css is importe before tailwind-css reset
// https://www.naiveui.com/en-US/light/docs/style-conflict
const meta = document.createElement("meta");
meta.name = "naive-ui-style";
document.head.appendChild(meta);

import { surveyPlugin } from "survey-vue3-ui";
//import stringifyCircularJson from "./helpers/stringifyCircularJson";

// setup logging
var originalFactory = log.methodFactory;
log.methodFactory = function (methodName, logLevel, loggerName) {
  var rawMethod = originalFactory(methodName, logLevel, loggerName);

  return function () {
    try {
      var messages = [];
      if (loggerName) messages.push(loggerName?.toString()  + ":");
      
      for (var i = 0; i < arguments.length; i++) {
        messages.push(arguments[i]);
      }
      rawMethod.apply(undefined, messages);

      // this has possible error in message serialization
      /* function translateLoglevelLevelToSentry(level: log.LogLevelNumbers): Sentry.SeverityLevel {
        switch (level) {
          case 0: case 1: return 'debug';
          case 2: return 'info';
          case 3: return 'warning';
          case 4: return 'error';
        }
        return 'log';
      }

      if (logLevel !== 5) { // silent
        Sentry.addBreadcrumb({
          category: loggerName ? loggerName?.toString() : "",
          message: stringifyCircularJson(messages),
          level: translateLoglevelLevelToSentry(logLevel)
        });
      } */
    } catch (e) {
      console.log(e);
    }
  };
};
//const logLevel = window._env_.VITE_ENV === "development" ? log.levels.INFO : log.levels.WARN;
log.setLevel(log.levels.DEBUG);

const app = createApp(App);
const pinia = createPinia();

// redirect to error page on error
app.config.errorHandler = (err, instance, info) => {
  log.error(err, instance, info);
  if (window._env_.VITE_ENV !== "development") {
    router.replace({ name: "error" });
  }
}

// mock http requests in development using msw
async function startMockService() {
  if (window._env_.VITE_ENV !== "development") {
    return;
  }
  const { worker } = await import('./mocks/browser');
  return worker.start();
}

// setup error logging to Sentry
try {
  if (window._env_.VITE_ENV !== "development") {
    Sentry.init({
      app,
      dsn: "https://ffd688e46b82ab8938f524f3321e4ee9@o1265032.ingest.sentry.io/4506180494491648",
      release: "onkoradce@" + (window._env_.APP_VERSION ?? '1.0.0'),
      environment: window._env_.VITE_ENV,
      integrations: [
        Sentry.browserTracingIntegration({ router }),
        Sentry.replayIntegration(),
      ],
      // Capture 100% of the transactions for Performance Monitoring
      tracesSampleRate: 1.0,
      // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
      tracePropagationTargets: [/^.*onkoradce.cz\/*/],
      // Session Replay
      replaysSessionSampleRate: 1.0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
  }
} catch (e) {
  log.error(e);
}

app
  .use(pinia) // used in router, so it has to be first
  .use(router)
  .use(urql, gatewayGql)
  .use(autoAnimatePlugin)
  .use(FormkitPlugin, FormkitDefaultConfig({
    locales: { cs },
    locale: 'cs',
  }))
  .use(surveyPlugin);

startMockService().then(() => {
  app.mount("#app");
})